import { ApplicationController } from "../../../../../../app/webpack_assets/support/application_controller"
import {
  calculateTotalPages,
  dateFormatter,
  minMaxFilterEditor,
  minMaxFilterFunction,
  presenceFilterFunction,
  tabulatorSV,
} from "../../../../../shared_assets/app/webpack_assets/shared/tabulator_customizations"
import { TabulatorFull as Tabulator } from "tabulator-tables"
import * as XLSX from "xlsx"

export default class extends ApplicationController {
  static targets = ["modalPocket", "modalDialog"]

  connect() {
    this._initializeTabulator()
  }

  changeFilter() {
    this.table.setData()
  }

  _initializeTabulator() {
    const table = new Tabulator("#payout-requests-table", {
      layout: "fitData",
      layoutColumnsOnNewData: true,
      ajaxURL: this.data.get("api-url"),
      dataSendParams: { size: "page_size" },
      ajaxResponse: (_url, _params, response) => {
        this.table_rowCount = response["meta"].total
        return this._transformResponse(response)
      },
      width: "100%",
      pagination: true,
      paginationMode: "remote",
      paginationSize: 25,
      paginationSizeSelector: [25, 50, 100],
      columns: [
        {
          title: "Datum",
          field: "created_at",
          formatter: dateFormatter,
          frozen: true,
          headerFilter: "input",
        },
        {
          title: "Utbetalningstyp",
          field: "payout_type",
          headerFilter: "list",
          headerFilterParams: {
            valuesLookup: true,
            clearable: true,
          },
          hozAlign: "center",
          width: "17%",
        },
        {
          title: "Skapad av",
          field: "creator_name",
          headerFilter: "input",
          width: "15%",
        },
        {
          title: "Utbetald till",
          field: "recipient_name",
          headerFilter: "input",
          width: "15%",
        },
        {
          title: "Summa (exkl skatt)",
          field: "amount",
          hozAlign: "right",
          formatter: "money",
          formatterParams: {
            precision: false,
            thousand: " ",
          },
          headerFilter: minMaxFilterEditor,
          headerFilterFunc: minMaxFilterFunction,
          headerFilterLiveFilter: false,
          width: "19%",
        },
        {
          title: "Skattesats",
          field: "tax_percentage",
          hozAlign: "right",
          formatter: "money",
          formatterParams: {
            symbol: "%",
            symbolAfter: true,
            precision: false,
          },
          headerFilter: "list",
          headerFilterParams: {
            valuesLookup: true,
            clearable: true,
          },
          width: "13%",
        },
        {
          title: "Beskrivning",
          field: "description",
          headerFilter: "input",
          width: "16%",
        },
        {
          title: "Status",
          field: "status",
          formatter: (cell, _formattedParams, _onRendered) => {
            switch (cell.getValue()) {
              case "pending":
                return "<span class='pending'></span><span>Väntande</span>"
              case "approved":
                return "<span class='approved'></span><span>Godkänd</span>"
              case "rejected":
                return "<span class='rejected'></span><span>Förkastad</span>"
              case "processing":
                return "<span class='processing'></span><span>Bearbetning</span>"
              case "payout_failed":
                return "<span class='payout_failed'></span><span>Misslyckad</span>"
              case "completed":
                return "<span class='completed'></span><span>Slutförd</span>"
              default:
                return cell.getValue()
            }
          },
          frozen: true,
          headerFilter: "list",
          headerFilterParams: {
            values: {
              pending: "Väntande",
              approved: "Godkänd",
              rejected: "Förkastad",
              processing: "Bearbetning",
              payout_failed: "Misslyckad",
              completed: "Slutförd",
            },
            clearable: true,
          },
        },
        {
          title: "Dokument",
          field: "document_url",
          download: false,
          headerSort: false,
          formatter: (cell, _formattedParams, _onRendered) => {
            if (cell.getValue() !== null) {
              return `<a href='${cell.getValue()}' target='_blank'><span class='payout-requests__document'></span></a>`
            }
          },
          headerFilter: "tickCross",
          headerFilterParams: {
            tristate: true,
          },
          headerFilterFunc: presenceFilterFunction,
        },
      ],
      locale: "sv",
      langs: tabulatorSV(),
    })

    table.on("rowClick", (e, row) => {
      const data = row.getData()
      this._showDialog(e, data.api_path)
    })

    this.payoutRequestsTable = table
    window.XLSX = XLSX
  }

  exportTable(event) {
    event.preventDefault()

    let table = this.payoutRequestsTable
    let page = table.getPage()
    let pageSize = table.getPageSize()
    table.setPageSize(this.table_rowCount)
    table.setPage(1).then(function () {
      table.download("xlsx", "Historik.xlsx", {
        sheetName: "Historik",
      })
      table.clearData()
      table.setPageSize(pageSize)
      table.setPage(page)
      table.restoreRedraw()
    })
  }

  _transformResponse(response) {
    const pageSize = this.payoutRequestsTable.getPageSize()
    const arr = []

    response.data.forEach((row) => {
      arr.push(row.attributes)
    })

    return { last_page: calculateTotalPages(response, pageSize), data: arr }
  }

  _showDialog(e, apiPath) {
    e.preventDefault()

    this.railsFetch(apiPath).then((value) => {
      this.modalPocketTarget.innerHTML = value.html
      setupModal("payout-request-modal")
      this.modalDialogTarget.classList.toggle("falldown")
    })
  }

  closeDialog(e) {
    e.preventDefault()
    this.modalDialogTarget.classList.toggle("falldown")
  }
}
